const user = {
  state: {
    version: 'v1.0.4-patch'
  },
  mutations: {
    ADD_USER(state, msg) {
      state.version = msg
    }
  },
  actions: {
  }
}

export default {
  // 表示允许  使用namespaced方法使用该模块，必须有
  namespaced: true,
  ...user
}
