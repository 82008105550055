









import { Component, Vue } from 'vue-property-decorator'
import Headers from '@/Layout/Headers.vue'
import Footer from '@/Layout/Footer.vue'

@Component({
  components: {
    Headers,
    Footer
  }
})
export default class App extends Vue {
  get key() {
    return this.$route.path
  }
}
