import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

/* Layout */
import Layout from '@/Layout/Container.vue'
import BlankContainer from '@/Layout/BlankContainer.vue'
import ContomContainer from '@/Layout/customContainer.vue'
import NoHeaderLayout from '@/Layout/NoHeaderContainer.vue'

Vue.use(VueRouter)
export const constantRoutes: RouteConfig[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/',
    name: 'home',
    children: [
      {
        path: '/',
        component: () => import('@/views/home/index.vue')
      }
    ]
  },
  {
    path: '/activityDetails',
    component: Layout,
    children: [
      {
        path: '/activityDetails',
        name: 'activity',
        component: () => import('@/views/activity/index.vue')
      }
    ]
  },
  {
    path: '/activityList',
    component: Layout,
    children: [
      {
        path: '/activityList',
        name: 'activityList',
        component: () => import('@/views/activity/activityList/index.vue')
      }
    ]
  },
  {
    path: '/actApply',
    component: BlankContainer,
    children: [
      {
        path: '/actApply',
        name: 'actApply',
        component: () => import('@/views/activity/actApply/index.vue')
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/user',
        name: 'User',
        component: () => import('@/views/personalCenter/index.vue')
      },
      {
        path: '/UserDetail',
        name: 'UserDetail',
        component: () => import('@/views/personalCenter/userDetail.vue')
      }
    ]
  },
  {
    path: '/userCenter',
    component: ContomContainer,
    children: [
      {
        path: '/userCenter',
        name: 'userCenter',
        component: () => import('@/views/personalCenter/userCenter.vue')
      }
    ]
  },
  {
    path: '/survey/:id',
    component: NoHeaderLayout,
    children: [
      {
        path: '/survey/:id',
        name: 'LinkSurvey',
        component: () => import('@/views/survey/link.vue')
      }
    ]
  },
  {
    path: '/activity/:id',
    component: BlankContainer,
    children: [
      {
        path: 'app_survey',
        name: 'ApplicationSurvey',
        component: () => import('@/views/survey/application.vue')
      }
    ]
  },
  {
    path: '/activity/:id',
    component: Layout,
    children: [
      {
        path: 'survey',
        name: 'Survey',
        component: () => import('@/views/survey/index.vue')
      }
    ]
  },
  {
    path: '/survey',
    component: BlankContainer,
    children: [
      {
        path: 'result/:type',
        name: 'SurveyResult',
        component: () => import('@/views/survey/result.vue')
      }
    ]
  },
  {
    path: '',
    component: BlankContainer,
    children: [
      {
        path: '',
        component: () => import('@/views/login/index.vue'),
        children: [
          {
            path: '/login/loginModel',
            component: () => import('@/views/login/component/loginCom.vue')
          },
          {
            path: '/login/createAccount',
            component: () => import('@/views/login/component/createAccount.vue')
          },
          {
            path: '/login/forgot',
            component: () => import('@/views/login/component/forgot.vue')
          },
          {
            path: '/login/changePassword',
            component: () => import('@/views/login/component/changePassword.vue')
          },
          {
            path: '/login/ResetPassword',
            component: () => import('@/views/login/component/ResetPassword.vue')
          },
          {
            path: '/login/activityAccount',
            component: () => import('@/views/login/component/activityAccount.vue')
          },
          {
            path: '/login/changePass',
            component: () => import('@/views/login/component/change.vue')
          },
          {
            path: '/login/applyAct',
            component: () => import('@/views/login/component/applyActivity.vue')
          },
          {
            path: '/login/successActPage',
            component: () => import('@/views/login/component/successActPage.vue')
          }
        ]
      }
    ]
  }
  // {
  //   path: '/demo',
  //   component: Layout,
  //   redirect: '/',
  //   children: [
  //     {
  //       path: '/',
  //       component: () => import(/* webpackChunkName: "dashboard" */ '@/views/demo/index.vue')
  //     }
  //   ]
  // }
]

const createRouter = () =>
  new VueRouter({
    mode: 'history', // Disabled due to Github Pages doesn't support this, enable this if you need.
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    },
    base: process.env.BASE_URL,
    routes: constantRoutes
  })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  ;(router as any).matcher = (newRouter as any).matcher // reset router
}

// 重复点击导航报错处理
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return (VueRouterPush.call(this, to) as any).catch((err:any) => err)
}
router.afterEach((to) => {
  if (to.fullPath === '/') {
    window.gtag('event', 'ViewHome', {
      event_desc: '用户访问【Home】页面'
    })
  } else if (to.fullPath === '/activityList') {
    window.gtag('event', 'ViewActivity', {
      event_desc: '用户访问【Activity】页面'
    })
  }
})
export default router
