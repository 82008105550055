




























































































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
})
export default class Headers extends Vue {
  @Prop({ type: String, required: true, default: 'default value' })
  id!: string
}
