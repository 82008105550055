

























import { Component, Vue } from 'vue-property-decorator'
import PrivacyPolicy from '@/components/Policy/privacy.vue'// svg component

@Component({
  components: { PrivacyPolicy },
  props: {
    homeLink: {
      type: Boolean
    }
  }
})
export default class Footer extends Vue {
  changeLang(val:any) {
    localStorage.setItem('locale', val)
    this.$i18n.locale = val
  }
  login() {
    this.$router.push('/login')
  }
}

