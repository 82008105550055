import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import '@/styles/index.scss' // global css
import '@/icons/index.js'
import './permission'
// 引入bootstrap
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// 图片查看插件
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import Vconsole from 'vconsole'
if (process.env.NODE_ENV !== 'production') {
  new Vconsole()
}
Vue.use(Viewer)
Viewer.setDefaults({
  'inline': false, // 启用inline模式
  'button': false, // 显示右上角关闭按钮
  'navbar': false, // 显示缩略图导航
  'title': false, // 显示当前图片的标题
  'toolbar': false, // 显示工具栏
  'tooltip': true, // 显示缩略百分比
  'movable': true, // 图片是否可移动
  'zoomable': true, // 图片是否可缩放
  'rotatable': true, // 图片是否可旋转
  'scalable': true, // 图片是否可反转
  'transition': true, // 使用css3过度
  'fullscreen': false, // 播放时是否全屏
  'keyboard': true //
})

import '../mock'
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)
import Component from 'vue-class-component'
// 将 metaInfo 加入到 Component 的事件中  不然 metaInfo 无效
Component.registerHooks([
  'metaInfo'
])

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const translationData = require('json-loader!yaml-loader!./lang/translationFile.yml')
// import Toast from './components/Toast/index.js'
// Vue.use(Toast)
import toast from './components/Toast/toast.vue'
Vue.component('Toast', toast)
// 默认英文
function getLocal() {
  // const cookies = document.cookie ? document.cookie.split('; ') : []
  // let locale:any = cookies.find((cookie) => decodeURIComponent(cookie).split('=')[0] === 'locale') || 'locale=en'
  // locale = locale.split('=')
  // return locale[1]
  return localStorage.getItem('locale') || 'en'
}
const locale = getLocal()
function getYmlData() {
  const enObj:any = {}
  const cnObj:any = {}

  Object.entries(translationData).forEach(([key, val]:any) => {
    const enStr:any = val.en
    const cnStr:any = val['zh-CN']
    enObj[key] = enStr || ''
    cnObj[key] = cnStr || ''
  })
  return {
    enObj,
    cnObj
  }
}
const { enObj, cnObj } = getYmlData()

const SHA256 = require('crypto-js/sha256')
const i18n = new VueI18n({
  locale,
  messages: {
    'zh': cnObj, // 中文语言包
    'en': enObj // 英文语言包
  }
})
// 在原来函数的基础上做一层封装，如果没有翻译默认返回中文
Vue.prototype.$spt = (i18n as any).$spt = (str:any, ...arg:any) => {
  const val = i18n.t(SHA256(str), ...arg)
  return val || str
}

Vue.config.productionTip = false
import store from './store/index.js'

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video
// Vue.use(Video)

const vue = new Vue({
  el: '#app',
  router,
  i18n,
  store,
  mounted() {
    document.dispatchEvent(new Event('render-event'))
    // document.dispatchEvent(new Event('custom-render-trigger'))
  },
  render: (h) => h(App)
}).$mount('#app')

export default vue
