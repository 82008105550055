




































































import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({})
export default class Headers extends Vue {
  isLogin: boolean = false
  isMobile: boolean = false
  avatar: string = ''
  currentPage: string = 'home'

  @Watch('$route', { immediate: true, deep: true })
  routeChange(route: any) {
    this.currentPage = route.name || 'home'
    const hasToken = localStorage.getItem('token')
    const userInfo = localStorage.getItem('userInfo')
    const user = JSON.parse(userInfo as any)
    this.avatar = user?.avatar || require('@/assets/images/user.png')
    this.isLogin = !!hasToken
  }
  toActivityPage() {
    this.currentPage = 'activityList'
    this.$router.push('/activityList')
  }
  toHomePage() {
    this.currentPage = 'home'
    this.$router.push('/')
  }
  toUserInfo() {
    this.$router.push('/user')
  }

  changeLang(val:any) {
    localStorage.setItem('locale', val)
    this.$i18n.locale = val
  }
  login() {
    // const url = `${window.location.origin}/login`
    // window.open(url, '_self')
    localStorage.removeItem('token')
    this.$router.push({
      path: '/login/loginModel'
    })
  }
  createAccount() {
    this.$router.push({
      path: '/login/createAccount'
    })
  }
  pageResize() {
    // console.log(document.body.clientWidth)
    this.isMobile = document.body.clientWidth <= 991
  }

  mounted() {
    // this.isLogin = !!localStorage.getItem('token')
    this.$nextTick(() => {
      this.isMobile = document.body.clientWidth <= 991
    })
    window.onresize = () => {
      this.pageResize()
    }
  }
  destroyed() {
    window.onresize = null
  }
}

