import router from './router'

const whiteList = ['/', '/login/loginModel', '/login/createAccount', '/login/forgot', '/login/ResetPassword', '/login/activityAccount', '/login/successActPage',
  '/activityDetails', '/activityDetails/', '/activityList', '/activityList/',
  '/survey/:id', '/survey/result/:type'
] // no redirect whitelist
router.beforeEach(async(to, from, next) => {
  // determine whether the user has logged in
  const hasToken = localStorage.getItem('token')
  const blackList = ['/login/loginModel']
  if (hasToken && hasToken !== 'undefined') {
    if (blackList.indexOf(to.path) === -1) {
      next()
    } else {
      next(`/activityList`)
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.matched ? to.matched[to.matched.length - 1].path : to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login/loginModel?redirect=${to.fullPath}`)
    }
  }
})
