






import { Component, Vue } from 'vue-property-decorator'
// import Headers from '@/Layout/Headers.vue'

@Component({
  // components: { Headers }
})
export default class App extends Vue {
  get key() {
    return this.$route.path
  }
}
